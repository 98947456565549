var render = function () {
  var _vm$loanData, _vm$loanData2, _vm$loanData3, _vm$loanData4, _vm$loanData5, _vm$loanData6, _vm$loanData7, _vm$loanData8, _vm$loanData9, _vm$loanData10, _vm$loanData11, _vm$installmentSummar, _vm$installmentSummar2, _vm$installmentSummar3, _vm$loanData12, _vm$loanData13, _vm$installmentSummar4, _vm$installmentSummar5, _vm$installmentSummar6, _vm$loanData14, _vm$loanData14$loanPo, _vm$loanData14$loanPo2, _vm$loanData15, _vm$loanData16, _vm$loanData16$loanPo, _vm$loanData16$loanPo2, _vm$loanData17, _vm$loanData17$loanPo, _vm$loanData17$loanPo2, _vm$loanData18, _vm$loanData19, _vm$loanData20, _vm$loanData21, _vm$loanData22, _vm$loanData23, _vm$loanData24, _vm$loanData25, _vm$loanData26, _vm$loanData27;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "custom-search d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.authUser.id == ((_vm$loanData = _vm.loanData) === null || _vm$loanData === void 0 ? void 0 : _vm$loanData.created_by) ? [((_vm$loanData2 = _vm.loanData) === null || _vm$loanData2 === void 0 ? void 0 : _vm$loanData2.status) != 'applied' && ((_vm$loanData3 = _vm.loanData) === null || _vm$loanData3 === void 0 ? void 0 : _vm$loanData3.status) != 'approved' && ((_vm$loanData4 = _vm.loanData) === null || _vm$loanData4 === void 0 ? void 0 : _vm$loanData4.status) != 'cancel' && ((_vm$loanData5 = _vm.loanData) === null || _vm$loanData5 === void 0 ? void 0 : _vm$loanData5.status) != 'final_apply' && ((_vm$loanData6 = _vm.loanData) === null || _vm$loanData6 === void 0 ? void 0 : _vm$loanData6.status) != 'rejected' ? _c('div', [[_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "gradient-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.loanReSubmitStatusUpdate();
      }
    }
  }, [_vm._v(" Re-Submit ")])], [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "gradient-warning"
    },
    on: {
      "click": function click($event) {
        return _vm.loanRejectStatusFinalApply();
      }
    }
  }, [_vm._v(" Final Apply ")])], [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "gradient-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.loanCancelStatusUpdate();
      }
    }
  }, [_vm._v(" Cancel ")])], [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "gradient-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onShowPage(_vm.loanData);
      }
    }
  }, [_vm._v(" Review ")])]], 2) : _vm._e()] : _vm._e(), _vm.$permissionAbility(_vm.LOAN_APPROVER_ACCESS, _vm.permissions) ? [((_vm$loanData7 = _vm.loanData) === null || _vm$loanData7 === void 0 ? void 0 : _vm$loanData7.status) == 'final_apply' ? _c('div', [_vm.loanApproveApiLoading ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 ml-1",
    attrs: {
      "variant": "gradient-success",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading ... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "gradient-success"
    },
    on: {
      "click": function click($event) {
        return _vm.loanApproveStatusUpdate();
      }
    }
  }, [_vm._v(" Approve ")])], [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "gradient-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.loanRejectStatusUpdate();
      }
    }
  }, [_vm._v(" Reject ")])]], 2) : _vm._e(), ((_vm$loanData8 = _vm.loanData) === null || _vm$loanData8 === void 0 ? void 0 : _vm$loanData8.status) != 'approved' && ((_vm$loanData9 = _vm.loanData) === null || _vm$loanData9 === void 0 ? void 0 : _vm$loanData9.status) != 'rejected' ? _c('div', [[_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "gradient-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onShowPage(_vm.loanData);
      }
    }
  }, [_vm._v(" Review ")])]], 2) : _vm._e()] : _vm._e()], 2)]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8",
      "md": "8"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Principal Amount")]), [_c('div', [_c('b-card-text', [_c('b', [_vm._v(_vm._s((_vm$loanData10 = _vm.loanData) === null || _vm$loanData10 === void 0 ? void 0 : _vm$loanData10.principal_amount) + " " + _vm._s(_vm.defaultCurrency))])])], 1)]], 2), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Total Amount")]), [_c('div', [_c('b-card-text', [_vm.totalInterests ? _c('b', [_vm._v(" " + _vm._s(((_vm$loanData11 = _vm.loanData) === null || _vm$loanData11 === void 0 ? void 0 : _vm$loanData11.principal_amount) + _vm.totalInterests) + " " + _vm._s(_vm.defaultCurrency))]) : _c('b', [_vm._v(" " + _vm._s((_vm$installmentSummar = _vm.installmentSummary) === null || _vm$installmentSummar === void 0 ? void 0 : _vm$installmentSummar.total_amount) + " " + _vm._s(_vm.defaultCurrency))])])], 1)]], 2)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Paid Amount")]), [_c('div', [_c('b-card-text', [_c('b', [_vm._v(" " + _vm._s((_vm$installmentSummar2 = _vm.installmentSummary) === null || _vm$installmentSummar2 === void 0 ? void 0 : _vm$installmentSummar2.paid_amount) + " " + _vm._s(_vm.defaultCurrency))])])], 1)]], 2), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Remaining Amount")]), [_c('div', [_c('b-card-text', [_c('b', [_vm._v(" " + _vm._s((_vm$installmentSummar3 = _vm.installmentSummary) === null || _vm$installmentSummar3 === void 0 ? void 0 : _vm$installmentSummar3.remaining_amount) + " " + _vm._s(_vm.defaultCurrency))])])], 1)]], 2)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Installment Start Date")]), [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.formattedDate((_vm$loanData12 = _vm.loanData) === null || _vm$loanData12 === void 0 ? void 0 : _vm$loanData12.installment_start_date)))])], 1)]], 2), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Installment End Date")]), [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.formattedDate((_vm$loanData13 = _vm.loanData) === null || _vm$loanData13 === void 0 ? void 0 : _vm$loanData13.installment_end_date)))])], 1)]], 2)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Total Installments")]), [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$installmentSummar4 = _vm.installmentSummary) === null || _vm$installmentSummar4 === void 0 ? void 0 : _vm$installmentSummar4.installments) + " ")])], 1)]], 2), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Paid")]), [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$installmentSummar5 = _vm.installmentSummary) === null || _vm$installmentSummar5 === void 0 ? void 0 : _vm$installmentSummar5.paid) + " ")])], 1)]], 2)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Remaining")]), [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$installmentSummar6 = _vm.installmentSummary) === null || _vm$installmentSummar6 === void 0 ? void 0 : _vm$installmentSummar6.remaining) + " ")])], 1)]], 2), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Total Interest Amount")]), [_c('div', [(_vm$loanData14 = _vm.loanData) !== null && _vm$loanData14 !== void 0 && (_vm$loanData14$loanPo = _vm$loanData14.loanPolicy) !== null && _vm$loanData14$loanPo !== void 0 && (_vm$loanData14$loanPo2 = _vm$loanData14$loanPo.data) !== null && _vm$loanData14$loanPo2 !== void 0 && _vm$loanData14$loanPo2.interest_rate ? _c('b-card-text', [_c('b', [_vm._v(_vm._s(_vm.totalInterests) + " " + _vm._s(_vm.defaultCurrency))])]) : _c('b-card-text', [_c('b', [_vm._v("0 " + _vm._s(_vm.defaultCurrency))])])], 1)]], 2)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Interest Rate")]), [_c('div', [_c('b-card-text', [(_vm$loanData15 = _vm.loanData) !== null && _vm$loanData15 !== void 0 && _vm$loanData15.loan_policy_id ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s((_vm$loanData16 = _vm.loanData) === null || _vm$loanData16 === void 0 ? void 0 : (_vm$loanData16$loanPo = _vm$loanData16.loanPolicy) === null || _vm$loanData16$loanPo === void 0 ? void 0 : (_vm$loanData16$loanPo2 = _vm$loanData16$loanPo.data) === null || _vm$loanData16$loanPo2 === void 0 ? void 0 : _vm$loanData16$loanPo2.interest_rate) + "%")]) : _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v("Not Set Yet")])], 1)], 1)]], 2), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Interest Policy")]), [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$loanData17 = _vm.loanData) === null || _vm$loanData17 === void 0 ? void 0 : (_vm$loanData17$loanPo = _vm$loanData17.loanPolicy) === null || _vm$loanData17$loanPo === void 0 ? void 0 : (_vm$loanData17$loanPo2 = _vm$loanData17$loanPo.data) === null || _vm$loanData17$loanPo2 === void 0 ? void 0 : _vm$loanData17$loanPo2.title))])], 1)]], 2)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Purpose")]), [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$loanData18 = _vm.loanData) === null || _vm$loanData18 === void 0 ? void 0 : _vm$loanData18.purpose))])], 1)]], 2), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Status")]), [_c('div', [_c('b-card-text', [((_vm$loanData19 = _vm.loanData) === null || _vm$loanData19 === void 0 ? void 0 : _vm$loanData19.status) === 'applied' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(" Applied ")]) : _vm._e(), ((_vm$loanData20 = _vm.loanData) === null || _vm$loanData20 === void 0 ? void 0 : _vm$loanData20.status) === 're_submit' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(" Re Submit ")]) : _vm._e(), ((_vm$loanData21 = _vm.loanData) === null || _vm$loanData21 === void 0 ? void 0 : _vm$loanData21.status) === 'sent_back' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-warning"
    }
  }, [_vm._v(" Sent Back ")]) : _vm._e(), ((_vm$loanData22 = _vm.loanData) === null || _vm$loanData22 === void 0 ? void 0 : _vm$loanData22.status) === 'final_apply' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-warning"
    }
  }, [_vm._v(" Final Apply ")]) : _vm._e(), ((_vm$loanData23 = _vm.loanData) === null || _vm$loanData23 === void 0 ? void 0 : _vm$loanData23.status) === 'cancel' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" Cancel ")]) : _vm._e(), ((_vm$loanData24 = _vm.loanData) === null || _vm$loanData24 === void 0 ? void 0 : _vm$loanData24.status) === 'rejected' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" Rejected ")]) : _vm._e(), ((_vm$loanData25 = _vm.loanData) === null || _vm$loanData25 === void 0 ? void 0 : _vm$loanData25.status) === 'approved' ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-success"
    }
  }, [_vm._v(" Approved ")]) : _vm._e()], 1)], 1)]], 2)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Remarks")]), [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$loanData26 = _vm.loanData) === null || _vm$loanData26 === void 0 ? void 0 : _vm$loanData26.remarks))])], 1)]], 2)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "4"
    }
  }, [_c('div', {
    staticStyle: {
      "max-height": "500px",
      "overflow-y": "auto"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Loan Activity Log"
    }
  }, [_c('app-timeline', [_vm._l(_vm.loanActivityLogsData, function (item) {
    var _item$properties, _item$properties$attr, _item$properties$attr2, _item$properties2, _item$properties2$old, _item$properties3, _item$properties3$att, _item$properties4, _item$properties4$att, _item$properties4$att2, _item$properties5, _item$properties5$old, _item$properties6, _item$properties6$old, _item$properties7, _item$properties7$old, _item$properties8, _item$properties8$old, _item$properties8$old2, _item$properties9, _item$properties9$old, _item$properties9$old2, _item$properties10, _item$properties10$at, _item$properties11, _item$properties11$ol, _item$properties12, _item$properties12$at, _item$properties13, _item$properties13$at, _item$properties14, _item$properties14$at, _item$properties15, _item$properties15$ol, _item$properties16, _item$properties16$at, _item$properties17, _item$properties17$at, _item$properties18, _item$properties18$at, _item$properties19, _item$properties19$ol, _item$properties20, _item$properties20$at, _item$properties21, _item$properties21$at, _item$properties22, _item$properties22$at, _item$properties22$at2, _item$properties23, _item$properties23$ol, _item$properties23$ol2, _item$properties24, _item$properties24$at, _item$properties24$at2, _item$properties25, _item$properties25$at, _item$properties25$at2, _item$properties26, _item$properties26$at, _item$properties26$at2, _item$properties27, _item$properties27$ol, _item$properties27$ol2, _item$properties28, _item$properties28$at, _item$properties28$at2, _item$properties29, _item$properties29$at, _item$properties29$at2, _item$properties30, _item$properties30$at, _item$properties30$at2, _item$properties31, _item$properties31$ol, _item$properties32, _item$properties32$ol, _item$properties33, _item$properties33$at, _item$properties34, _item$properties34$at;
    return [(item === null || item === void 0 ? void 0 : item.log_name) == 'loans' && (item === null || item === void 0 ? void 0 : item.description) == 'created' ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "title": "Loan Created by ".concat(item === null || item === void 0 ? void 0 : (_item$properties = item.properties) === null || _item$properties === void 0 ? void 0 : (_item$properties$attr = _item$properties.attributes) === null || _item$properties$attr === void 0 ? void 0 : (_item$properties$attr2 = _item$properties$attr.createdBy) === null || _item$properties$attr2 === void 0 ? void 0 : _item$properties$attr2.name),
        "subtitle": _vm.formatDateTime(item === null || item === void 0 ? void 0 : item.created_at),
        "time": _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at),
        "icon": "InfoIcon",
        "variant": "primary"
      }
    })] : (item === null || item === void 0 ? void 0 : item.log_name) == 'loans' && (item === null || item === void 0 ? void 0 : item.description) == 'updated' && (item === null || item === void 0 ? void 0 : (_item$properties2 = item.properties) === null || _item$properties2 === void 0 ? void 0 : (_item$properties2$old = _item$properties2.old) === null || _item$properties2$old === void 0 ? void 0 : _item$properties2$old.status) == (item === null || item === void 0 ? void 0 : (_item$properties3 = item.properties) === null || _item$properties3 === void 0 ? void 0 : (_item$properties3$att = _item$properties3.attributes) === null || _item$properties3$att === void 0 ? void 0 : _item$properties3$att.status) ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "icon": "InfoIcon",
        "variant": "info"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }, [_c('h6', [_vm._v(" Loan Data Updated By " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties4 = item.properties) === null || _item$properties4 === void 0 ? void 0 : (_item$properties4$att = _item$properties4.attributes) === null || _item$properties4$att === void 0 ? void 0 : (_item$properties4$att2 = _item$properties4$att.updatedBy) === null || _item$properties4$att2 === void 0 ? void 0 : _item$properties4$att2.name) + " ")]), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(" Loan Data Update Report ")]), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at)))]), _c('span', {
      staticClass: "align-bottom"
    }, [_vm._v(_vm._s(_vm.formatDateTime(item === null || item === void 0 ? void 0 : item.created_at)))]), _c('br'), _c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: "milestone-change-list-".concat(item === null || item === void 0 ? void 0 : item.id),
        expression: "`milestone-change-list-${item?.id}`"
      }, {
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "mt-1",
      attrs: {
        "size": "sm",
        "variant": "outline-primary"
      }
    }, [_vm._v(" Show Changes ")]), _c('b-collapse', {
      attrs: {
        "id": "milestone-change-list-".concat(item === null || item === void 0 ? void 0 : item.id)
      }
    }, [_c('b-row', {
      staticClass: "custom-font"
    }, [_c('b-col', {
      staticClass: "pr-1",
      attrs: {
        "cols": "6"
      }
    }, [_c('b-list-group', {
      staticClass: "mt-1",
      attrs: {
        "flush": ""
      }
    }, [_c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center bg-transparent"
    }, [_c('span', [_c('b', [_vm._v("Previous")])])]), _c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center bg-transparent"
    }, [_c('span', [_c('b', [_vm._v("Principal Amount:")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties5 = item.properties) === null || _item$properties5 === void 0 ? void 0 : (_item$properties5$old = _item$properties5.old) === null || _item$properties5$old === void 0 ? void 0 : _item$properties5$old.principal_amount))])]), _c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center bg-transparent"
    }, [_c('span', [_c('b', [_vm._v("Installment Start Date:")]), _vm._v(" " + _vm._s(_vm.formattedDate(item === null || item === void 0 ? void 0 : (_item$properties6 = item.properties) === null || _item$properties6 === void 0 ? void 0 : (_item$properties6$old = _item$properties6.old) === null || _item$properties6$old === void 0 ? void 0 : _item$properties6$old.installment_start_date)))])]), _c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center bg-transparent"
    }, [_c('span', [_c('b', [_vm._v("Installment End Date:")]), _vm._v(" " + _vm._s(_vm.formattedDate(item === null || item === void 0 ? void 0 : (_item$properties7 = item.properties) === null || _item$properties7 === void 0 ? void 0 : (_item$properties7$old = _item$properties7.old) === null || _item$properties7$old === void 0 ? void 0 : _item$properties7$old.installment_end_date)))])]), _c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center bg-transparent"
    }, [_c('span', [_c('b', [_vm._v("Loan Policy:")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties8 = item.properties) === null || _item$properties8 === void 0 ? void 0 : (_item$properties8$old = _item$properties8.old) === null || _item$properties8$old === void 0 ? void 0 : (_item$properties8$old2 = _item$properties8$old.loanPolicy) === null || _item$properties8$old2 === void 0 ? void 0 : _item$properties8$old2.title))])]), _c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center bg-transparent"
    }, [_c('span', [_c('b', [_vm._v("Interest Rate:")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties9 = item.properties) === null || _item$properties9 === void 0 ? void 0 : (_item$properties9$old = _item$properties9.old) === null || _item$properties9$old === void 0 ? void 0 : (_item$properties9$old2 = _item$properties9$old.loanPolicy) === null || _item$properties9$old2 === void 0 ? void 0 : _item$properties9$old2.interest_rate) + " %")])])], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('b-list-group', {
      staticClass: "mt-1",
      attrs: {
        "flush": ""
      }
    }, [_c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center bg-transparent"
    }, [_c('span', [_c('b', [_vm._v("Updated")])])]), _c('b-list-group-item', {
      staticClass: "justify-content-between align-items-center bg-transparent"
    }, [_c('b', [_vm._v("Principal Amount: ")]), (item === null || item === void 0 ? void 0 : (_item$properties10 = item.properties) === null || _item$properties10 === void 0 ? void 0 : (_item$properties10$at = _item$properties10.attributes) === null || _item$properties10$at === void 0 ? void 0 : _item$properties10$at.principal_amount) !== (item === null || item === void 0 ? void 0 : (_item$properties11 = item.properties) === null || _item$properties11 === void 0 ? void 0 : (_item$properties11$ol = _item$properties11.old) === null || _item$properties11$ol === void 0 ? void 0 : _item$properties11$ol.principal_amount) ? [_c('span', {
      staticClass: "text-danger"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties12 = item.properties) === null || _item$properties12 === void 0 ? void 0 : (_item$properties12$at = _item$properties12.attributes) === null || _item$properties12$at === void 0 ? void 0 : _item$properties12$at.principal_amount))])] : [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties13 = item.properties) === null || _item$properties13 === void 0 ? void 0 : (_item$properties13$at = _item$properties13.attributes) === null || _item$properties13$at === void 0 ? void 0 : _item$properties13$at.principal_amount) + " ")])]], 2), _c('b-list-group-item', {
      staticClass: "justify-content-between align-items-center bg-transparent"
    }, [_c('b', [_vm._v("Installment Start Date:")]), (item === null || item === void 0 ? void 0 : (_item$properties14 = item.properties) === null || _item$properties14 === void 0 ? void 0 : (_item$properties14$at = _item$properties14.attributes) === null || _item$properties14$at === void 0 ? void 0 : _item$properties14$at.installment_start_date) !== (item === null || item === void 0 ? void 0 : (_item$properties15 = item.properties) === null || _item$properties15 === void 0 ? void 0 : (_item$properties15$ol = _item$properties15.old) === null || _item$properties15$ol === void 0 ? void 0 : _item$properties15$ol.installment_start_date) ? [_c('span', {
      staticClass: "text-danger"
    }, [_vm._v(" " + _vm._s(_vm.formattedDate(item === null || item === void 0 ? void 0 : (_item$properties16 = item.properties) === null || _item$properties16 === void 0 ? void 0 : (_item$properties16$at = _item$properties16.attributes) === null || _item$properties16$at === void 0 ? void 0 : _item$properties16$at.installment_start_date)))])] : [_c('span', [_vm._v(" " + _vm._s(_vm.formattedDate(item === null || item === void 0 ? void 0 : (_item$properties17 = item.properties) === null || _item$properties17 === void 0 ? void 0 : (_item$properties17$at = _item$properties17.attributes) === null || _item$properties17$at === void 0 ? void 0 : _item$properties17$at.installment_start_date)) + " ")])]], 2), _c('b-list-group-item', {
      staticClass: "justify-content-between align-items-center bg-transparent"
    }, [_c('b', [_vm._v("Installment End Date:")]), (item === null || item === void 0 ? void 0 : (_item$properties18 = item.properties) === null || _item$properties18 === void 0 ? void 0 : (_item$properties18$at = _item$properties18.attributes) === null || _item$properties18$at === void 0 ? void 0 : _item$properties18$at.installment_end_date) !== (item === null || item === void 0 ? void 0 : (_item$properties19 = item.properties) === null || _item$properties19 === void 0 ? void 0 : (_item$properties19$ol = _item$properties19.old) === null || _item$properties19$ol === void 0 ? void 0 : _item$properties19$ol.installment_end_date) ? [_c('span', {
      staticClass: "text-danger"
    }, [_vm._v(" " + _vm._s(_vm.formattedDate(item === null || item === void 0 ? void 0 : (_item$properties20 = item.properties) === null || _item$properties20 === void 0 ? void 0 : (_item$properties20$at = _item$properties20.attributes) === null || _item$properties20$at === void 0 ? void 0 : _item$properties20$at.installment_end_date)))])] : [_c('span', [_vm._v(" " + _vm._s(_vm.formattedDate(item === null || item === void 0 ? void 0 : (_item$properties21 = item.properties) === null || _item$properties21 === void 0 ? void 0 : (_item$properties21$at = _item$properties21.attributes) === null || _item$properties21$at === void 0 ? void 0 : _item$properties21$at.installment_end_date)) + " ")])]], 2), _c('b-list-group-item', {
      staticClass: "justify-content-between align-items-center bg-transparent"
    }, [_c('b', [_vm._v("Loan Policy:")]), (item === null || item === void 0 ? void 0 : (_item$properties22 = item.properties) === null || _item$properties22 === void 0 ? void 0 : (_item$properties22$at = _item$properties22.attributes) === null || _item$properties22$at === void 0 ? void 0 : (_item$properties22$at2 = _item$properties22$at.loanPolicy) === null || _item$properties22$at2 === void 0 ? void 0 : _item$properties22$at2.title) !== (item === null || item === void 0 ? void 0 : (_item$properties23 = item.properties) === null || _item$properties23 === void 0 ? void 0 : (_item$properties23$ol = _item$properties23.old) === null || _item$properties23$ol === void 0 ? void 0 : (_item$properties23$ol2 = _item$properties23$ol.loanPolicy) === null || _item$properties23$ol2 === void 0 ? void 0 : _item$properties23$ol2.title) ? [_c('span', {
      staticClass: "text-danger"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties24 = item.properties) === null || _item$properties24 === void 0 ? void 0 : (_item$properties24$at = _item$properties24.attributes) === null || _item$properties24$at === void 0 ? void 0 : (_item$properties24$at2 = _item$properties24$at.loanPolicy) === null || _item$properties24$at2 === void 0 ? void 0 : _item$properties24$at2.title))])] : [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties25 = item.properties) === null || _item$properties25 === void 0 ? void 0 : (_item$properties25$at = _item$properties25.attributes) === null || _item$properties25$at === void 0 ? void 0 : (_item$properties25$at2 = _item$properties25$at.loanPolicy) === null || _item$properties25$at2 === void 0 ? void 0 : _item$properties25$at2.title) + " ")])]], 2), _c('b-list-group-item', {
      staticClass: "justify-content-between align-items-center bg-transparent"
    }, [_c('b', [_vm._v("Interest Rate:")]), (item === null || item === void 0 ? void 0 : (_item$properties26 = item.properties) === null || _item$properties26 === void 0 ? void 0 : (_item$properties26$at = _item$properties26.attributes) === null || _item$properties26$at === void 0 ? void 0 : (_item$properties26$at2 = _item$properties26$at.loanPolicy) === null || _item$properties26$at2 === void 0 ? void 0 : _item$properties26$at2.interest_rate) !== (item === null || item === void 0 ? void 0 : (_item$properties27 = item.properties) === null || _item$properties27 === void 0 ? void 0 : (_item$properties27$ol = _item$properties27.old) === null || _item$properties27$ol === void 0 ? void 0 : (_item$properties27$ol2 = _item$properties27$ol.loanPolicy) === null || _item$properties27$ol2 === void 0 ? void 0 : _item$properties27$ol2.interest_rate) ? [_c('span', {
      staticClass: "text-danger"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties28 = item.properties) === null || _item$properties28 === void 0 ? void 0 : (_item$properties28$at = _item$properties28.attributes) === null || _item$properties28$at === void 0 ? void 0 : (_item$properties28$at2 = _item$properties28$at.loanPolicy) === null || _item$properties28$at2 === void 0 ? void 0 : _item$properties28$at2.interest_rate) + " % ")])] : [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties29 = item.properties) === null || _item$properties29 === void 0 ? void 0 : (_item$properties29$at = _item$properties29.attributes) === null || _item$properties29$at === void 0 ? void 0 : (_item$properties29$at2 = _item$properties29$at.loanPolicy) === null || _item$properties29$at2 === void 0 ? void 0 : _item$properties29$at2.interest_rate) + " % ")])]], 2)], 1)], 1)], 1)], 1)], 1)])] : [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "icon": "InfoIcon",
        "variant": "warning"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }, [_c('h6', [_vm._v(" Loan Status Updated By " + _vm._s(item === null || item === void 0 ? void 0 : (_item$properties30 = item.properties) === null || _item$properties30 === void 0 ? void 0 : (_item$properties30$at = _item$properties30.attributes) === null || _item$properties30$at === void 0 ? void 0 : (_item$properties30$at2 = _item$properties30$at.updatedBy) === null || _item$properties30$at2 === void 0 ? void 0 : _item$properties30$at2.name) + " ")]), item !== null && item !== void 0 && (_item$properties31 = item.properties) !== null && _item$properties31 !== void 0 && (_item$properties31$ol = _item$properties31.old) !== null && _item$properties31$ol !== void 0 && _item$properties31$ol.status ? _c('small', {
      staticClass: "text-muted"
    }, [_c('h6', [_vm._v(" Loan Status Updated "), _c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(" " + _vm._s(_vm.getLoanStatusName(item === null || item === void 0 ? void 0 : (_item$properties32 = item.properties) === null || _item$properties32 === void 0 ? void 0 : (_item$properties32$ol = _item$properties32.old) === null || _item$properties32$ol === void 0 ? void 0 : _item$properties32$ol.status)) + " ")]), _vm._v(" to "), _c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(" " + _vm._s(_vm.getLoanStatusName(item === null || item === void 0 ? void 0 : (_item$properties33 = item.properties) === null || _item$properties33 === void 0 ? void 0 : (_item$properties33$at = _item$properties33.attributes) === null || _item$properties33$at === void 0 ? void 0 : _item$properties33$at.status)) + " ")])], 1)]) : _c('small', {
      staticClass: "text-muted"
    }, [_c('h6', [_vm._v(" Loan created and status changed to "), _c('b-badge', {
      staticClass: "badge-glow",
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(" " + _vm._s(_vm.getLoanStatusName(item === null || item === void 0 ? void 0 : (_item$properties34 = item.properties) === null || _item$properties34 === void 0 ? void 0 : (_item$properties34$at = _item$properties34.attributes) === null || _item$properties34$at === void 0 ? void 0 : _item$properties34$at.status)) + " ")])], 1)]), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at)))]), _c('span', {
      staticClass: "align-bottom"
    }, [_vm._v(_vm._s(_vm.formatDateTime(item === null || item === void 0 ? void 0 : item.created_at)))])])])]];
  })], 2)], 1)], 1)])], 1), _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-loan-form",
      "centered": "",
      "title": " Edit Loan Details ",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "loanFormValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "12",
      "lg": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Principal Amount",
      "label-for": "principal_amount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "principal amount",
      "vid": "principal_amount",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "principal_amount",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Principal Amount",
            "disabled": _vm.loanApplierFormFieldShowStatus
          },
          model: {
            value: _vm.principalAmount,
            callback: function callback($$v) {
              _vm.principalAmount = $$v;
            },
            expression: "principalAmount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "12",
      "lg": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Purpose",
      "label-for": "purpose"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Purpose",
      "vid": "purpose",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "purpose",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Purpose",
            "rows": "3",
            "disabled": _vm.loanApplierFormFieldShowStatus
          },
          model: {
            value: _vm.purposeText,
            callback: function callback($$v) {
              _vm.purposeText = $$v;
            },
            expression: "purposeText"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Installment Start Date",
      "label-for": "installment_start_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "installment start date",
      "vid": "installment_start_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-datepicker', {
          staticClass: "custom-font",
          attrs: {
            "id": "installment_start_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "disabled": _vm.loanApplierFormFieldShowStatus
          },
          on: {
            "context": _vm.onContextLoanDate
          },
          model: {
            value: _vm.installmentStartDate,
            callback: function callback($$v) {
              _vm.installmentStartDate = $$v;
            },
            expression: "installmentStartDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Installment End Date",
      "label-for": "installment_end_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "installment end date",
      "vid": "installment_end_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-datepicker', {
          staticClass: "custom-font",
          attrs: {
            "id": "installment_end_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "disabled": _vm.loanApplierFormFieldShowStatus
          },
          on: {
            "context": _vm.onContextLoanDate
          },
          model: {
            value: _vm.installmentEndDate,
            callback: function callback($$v) {
              _vm.installmentEndDate = $$v;
            },
            expression: "installmentEndDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "12",
      "lg": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Loan Policies",
      "label-for": "loan_policy_id"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "loan policies",
      "vid": "loan_policy_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          attrs: {
            "id": "loan_policy_id",
            "options": _vm.filterLoanPolicyIdOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Here",
            "disabled": _vm.loanApproverFormFieldShowStatus
          },
          model: {
            value: _vm.loanPolicyId,
            callback: function callback($$v) {
              _vm.loanPolicyId = $$v;
            },
            expression: "loanPolicyId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "12",
      "lg": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Remarks",
      "label-for": "remarks"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Remarks",
      "vid": "remarks"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Remarks",
            "rows": "3",
            "disabled": _vm.loanApproverFormFieldShowStatus
          },
          model: {
            value: _vm.remarksText,
            callback: function callback($$v) {
              _vm.remarksText = $$v;
            },
            expression: "remarksText"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1), _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-extra-amount-form",
      "centered": "",
      "title": " Add Extra Amount In Installment ",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenExtraAmountModal
    }
  }, [_c('validation-observer', {
    ref: "loanExtraAmountFormValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationExtraAmountForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Title ",
      "label-for": "title"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Title"
          },
          model: {
            value: _vm.extraAmountTitle,
            callback: function callback($$v) {
              _vm.extraAmountTitle = $$v;
            },
            expression: "extraAmountTitle"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Account ",
      "label-for": "account"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "account",
      "vid": "account_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          attrs: {
            "id": "account",
            "placeholder": "Choose an Account",
            "options": _vm.accountsOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectAccountId,
            callback: function callback($$v) {
              _vm.selectAccountId = $$v;
            },
            expression: "selectAccountId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "12",
      "lg": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "With which installment you want to add the extra amount",
      "label-for": "installment_number"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Installment Number",
      "vid": "installment_number",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('v-select', {
          attrs: {
            "id": "installment_number",
            "placeholder": "Choose an installment number",
            "options": _vm.installmentNumberOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.installmentNumber,
            callback: function callback($$v) {
              _vm.installmentNumber = $$v;
            },
            expression: "installmentNumber"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "12",
      "lg": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Extra Amount",
      "label-for": "extra_amount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Extra Amount",
      "vid": "extra_amount",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "extra_amount",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Extra Amount"
          },
          model: {
            value: _vm.extraAmount,
            callback: function callback($$v) {
              _vm.extraAmount = $$v;
            },
            expression: "extraAmount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "12",
      "lg": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Remarks ",
      "label-for": "Remarks"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "remarks",
      "vid": "remarks",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Add Remarks"
          },
          model: {
            value: _vm.extraRemarks,
            callback: function callback($$v) {
              _vm.extraRemarks = $$v;
            },
            expression: "extraRemarks"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.isExtraAmountSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Re-Generate Installments ")])]], 2)], 1)], 1)], 1), _c('b-card', [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8",
      "md": "8"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Installment Breakdown")])]), _vm.$permissionAbility(_vm.LOAN_APPROVER_ACCESS, _vm.permissions) && ((_vm$loanData27 = _vm.loanData) === null || _vm$loanData27 === void 0 ? void 0 : _vm$loanData27.status) === 'approved' ? _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "variant": "gradient-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onShowExtraAmountPage(_vm.loanData);
      }
    }
  }, [_vm._v(" Add Extra Amount ")])], 1) : _vm._e()], 1), _c('b-table', {
    staticClass: "mt-1",
    attrs: {
      "items": _vm.installmentsBreakdown,
      "fields": _vm.tableFields
    },
    scopedSlots: _vm._u([{
      key: "cell(#)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(date)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.date) + " ")];
      }
    }, {
      key: "cell(monthly_payment)",
      fn: function fn(data) {
        return [_c('FormatCurrency', {
          attrs: {
            "currency": _vm.defaultCurrency,
            "amount": data.item.monthly_payment
          }
        })];
      }
    }, {
      key: "cell(principal_amount)",
      fn: function fn(data) {
        return [_c('FormatCurrency', {
          attrs: {
            "currency": _vm.defaultCurrency,
            "amount": data.item.principal_amount
          }
        })];
      }
    }, {
      key: "cell(interest_amount)",
      fn: function fn(data) {
        return [_c('FormatCurrency', {
          attrs: {
            "currency": _vm.defaultCurrency,
            "amount": data.item.interest_amount
          }
        })];
      }
    }, {
      key: "cell(remaining_principal)",
      fn: function fn(data) {
        return [_c('FormatCurrency', {
          attrs: {
            "currency": _vm.defaultCurrency,
            "amount": data.item.remaining_principal
          }
        })];
      }
    }, {
      key: "cell(is_paid)",
      fn: function fn(data) {
        var _data$item;
        return [((_data$item = data.item) === null || _data$item === void 0 ? void 0 : _data$item.is_paid) == 1 ? _c('div', [_vm._v(" Paid ")]) : _c('div', [_vm._v(" Not Paid ")])];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }